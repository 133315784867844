<template>
  <div class="">
    <b-row>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal icon="UsersIcon" :statistic="chart.user" statistic-title="Pengguna" />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal icon="StarIcon" color="success" :statistic="chart.paket" statistic-title="Paket Tryout" />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal icon="BookIcon" color="info" :statistic="chart.ebook" statistic-title="E-book" />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal icon="FileTextIcon" color="warning" :statistic="chart.article" statistic-title="Artikel" />
      </b-col>
    </b-row>
    <!-- <chart /> -->
    <bar />
  </div>
</template>

<script>
import Bar from "./ChartjsBarChart.vue";
import Chart from "./Chart.vue";
import { BRow, BCol, BCard } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    StatisticCardHorizontal,
    Chart,
    Bar,
  },
  data() {
    return {
      // apexChatData,
      chart: {},
    };
  },
  methods: {
    getData() {
      this.loading = true;
      const params = {
        // type: "page",
        // filter_month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        // filter_month:
      };
      this.$store
        .dispatch("dashboard/indexCard", params)
        .then((response) => {
          this.loading = false;
          let chart = response.data;
          this.chart = chart;
          this.totalRows = this.chart.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
