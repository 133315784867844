<template>
  <!-- <b-card no-body>
    <b-card-header>
      <b-card-title>Latest Statistics</b-card-title>
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="start_date"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
    </b-card-header>

    <b-card-body>

      <chartjs-component-line-chart
        :height="400"
        :data="chartpenjualan.data"
        :options="chartpenjualan.options"
        :plugins="plugins"
      />
      {{ this.chartpenjualan }}
      <hr />
    </b-card-body>
  </b-card> -->
  <b-card no-body>
    <b-card-header>
      <b-card-title>Grafik Penjualan</b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <b-card-text>
        <!-- <div class="d-flex align-items-center"> -->
        <b-row>
          <b-col cols="4">
            <b-form-group>
              <h5 class="font-weight-bold">Tanggal Awal</h5>
              <!-- <label>Tanggal Awal</label> -->
              <!-- <feather-icon icon="CalendarIcon" size="16" /> -->
              <flat-pickr
                v-model="start_date"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <!-- </div>
        <div class="d-flex align-items-center"> -->
          <b-col cols="4">
            <b-form-group>
              <h5 class="font-weight-bold">Tanggal Akhir</h5>
              <!-- <label>Tanggal Akhir</label> -->
              <!-- <feather-icon icon="CalendarIcon" size="16" /> -->
              <flat-pickr
                v-model="end_date"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <!-- </div>
        <div class="d-flex align-items-center"> -->
          <b-col cols="4">
            <b-form-group>
              <h5 class="font-weight-bold">-</h5>
              <!-- <label>Filter</label> -->
              <b-button variant="outline-primary" @click="getData"
                >Update Grafik</b-button
              >
              <!-- </div> --></b-form-group
            >
          </b-col>
        </b-row>
      </b-card-text>
      <!-- <chartjs-component-line-chart
        :height="400"
        :data="chartjsData.lineChart.data"
        :options="chartjsData.lineChart.options"
        :plugins="plugins"
      /> -->
      <chartjs-component-line-chart
        v-if="loaded"
        :height="500"
        :data="chartpenjualan.data"
        :options="chartpenjualan.options"
        :plugins="plugins"
      />
      <!-- {{ this.chartpenjualan }} -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BCardText,
  BFormGroup,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue";
import ChartjsComponentLineChart from "./charts-components/ChartjsComponentLineChart.vue";
import chartjsData from "./chartjsData";
import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "#ffffff",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  lineChartSuccess: "#49be25",
  black: "#000000",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BFormGroup,
    flatPickr,
    ChartjsComponentBarChart,
    ChartjsComponentLineChart,
  },
  data: () => ({
    chartjsData,
    plugins: [
      // to add spacing between legends and chart
      {
        beforeInit(chart) {
          /* eslint-disable func-names, no-param-reassign */
          chart.legend.afterFit = function () {
            this.height += 20;
          };
          /* eslint-enable */
        },
      },
    ],
    loaded: false,
    start_date: null,
    end_date: null,
    chartpenjualan: null,
    chartpenjualan2: {},
    label: {},
    chartpenjualanPaket: {},
    chartpenjualanEbook: {},
    chartpenjualanSnbp: {},
  }),
  computed: {
    // getData() {
    //   return; /* mutable chart data */
    // },
    chartpenjualan() {
      return;
    },
    // chartOptions() { return /* mutable chart options */ }
  },
  methods: {
    async getData() {
      this.loaded = false;
      this.loading = true;
      const params = {
        // type: "page",
        start_date: this.start_date,
        end_date: this.end_date,
        // filter_month:
      };

      try {
        const response = await this.$store.dispatch("dashboard/index", params);
        // this.$store
        //   .dispatch("dashboard/index", params)
        //   .then((response) => {
        // let cek = response.data;
        this.label = response.data.labels;
        const cek2 = response.data.datasets;
        const newPaket = cek2.map((v) => {
          return v.label == "Paket Tryout"
            ? {
                ...v,
                borderColor: chartColors.lineChartSuccess,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.lineChartSuccess,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: chartColors.tooltipShadow,
                pointHoverBackgroundColor: chartColors.lineChartSuccess,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow,
              }
            : v;
        });
        const newEbook = newPaket.map((v) => {
          return v.label == "Ebook"
            ? {
                ...v,
                borderColor: chartColors.lineChartPrimary,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.lineChartPrimary,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: chartColors.tooltipShadow,
                pointHoverBackgroundColor: chartColors.lineChartPrimary,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow,
              }
            : v;
        });
        const newSnbp = newEbook.map((v) => {
          return v.label == "SNBP"
            ? {
                ...v,
                borderColor: chartColors.warningColorShade,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.warningColorShade,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: chartColors.tooltipShadow,
                pointHoverBackgroundColor: chartColors.warningColorShade,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow,
              }
            : v;
        });
        // this.chartpenjualanPaket = newPaket;
        // this.chartpenjualanEbook = newEbook;
        this.chartpenjualanSnbp = newSnbp;
        let pay = {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.black,
            backgroundColor: chartColors.tooltipShadow,
            titleFontColor: chartColors.black,
            bodyFontColor: chartColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 500000,
                  min: 0,
                  max: 5000000,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        };
        let payload = {
          labels: this.label,
          datasets: this.chartpenjualanSnbp,
        };
        let akhir = {
          options: pay,
          data: payload,
        };
        this.chartpenjualan = akhir;
        this.loading = false;
        this.loaded = true;
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
