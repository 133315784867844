import { $themeColors } from "@themeConfig";

// heat chart data generator
function generateDataHeatMap(count, yrange) {
  let i = 0;
  const series = [];
  while (i < count) {
    const x = `w${(i + 1).toString()}`;
    const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x,
      y,
    });
    // eslint-disable-next-line no-plusplus
    i++;
  }
  return series;
}

// colors
const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",
  },
};

export default {
  dataPenjualan: {
    series: [
      {
        data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.primary],
      },
      colors: [$themeColors.primary],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]}%</span></div>`;
        },
      },
      xaxis: {
        categories: ["7/12", "8/12", "9/12", "10/12", "11/12", "12/12", "13/12", "14/12", "15/12", "16/12", "17/12", "18/12", "19/12", "20/12", "21/12"],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },
  dataPendapatan: {
    series: [
      {
        data: [100, 500, 100, 280, 230, 350, 70, 80, 70, 150, 160, 100, 150, 100, 50],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.success],
      },
      colors: [$themeColors.success],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]}%</span></div>`;
        },
      },
      xaxis: {
        categories: ["7/12", "8/12", "9/12", "10/12", "11/12", "12/12", "13/12", "14/12", "15/12", "16/12", "17/12", "18/12", "19/12", "20/12", "21/12"],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },
  barChart: {
    series: [
      {
        data: [700, 350, 480, 600, 210, 550, 150],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: $themeColors.info,
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          endingShape: 'rounded',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['MON, 11', 'THU, 14', 'FRI, 15', 'MON, 18', 'WED, 20', 'FRI, 21', 'MON, 23'],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },
};
